import { useEffect, useState } from 'react';
import supabase from './supabaseClient';

function useAuth() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Fetch initial session
    const fetchSession = async () => {
      const { data: { session }, error } = await supabase.auth.getSession();
      if (error) {
        console.error('Error fetching session:', error.message);
      } else {
        setUser(session?.user ?? null);
      }
    };

    fetchSession();

    // Listen for changes
    const { data: { subscription } } = supabase.auth.onAuthStateChange((event, session) => {
      setUser(session?.user ?? null);
    });

    // Clean up listener
    return () => {
      subscription?.unsubscribe();
    };
  }, []);

  return user;
}

export default useAuth;
