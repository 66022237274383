import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import useAuth from "../hooks/useAuth";

// Import your pages/components
import * as Pages from "../routers/pages.router";
import * as Globals from "../routers/globals.router";

function App() {
  const user = useAuth();

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Pages.Home />} />
        <Route path="dashboard" element={!user ? (
          <Pages.Auth />
        ) : (
          <Pages.Dashboard />
        )} />
        <Route path="add-post" element={user ? <Globals.AddPost /> : <Pages.Auth />} />
        <Route path="blog-list" element={<Globals.PostsList />} />
        <Route path="edit-post/:id" element={user ? <Globals.EditPost /> : <Pages.Auth />} />
      </Routes>
    </Router>
  );
}

export default App;
